import React from 'react';
import PageLayout from "../components/PageLayout";
import Animation from "../components/Animation";
import logo from "../images/logo.json";
const Success = () => {
    return(
        <PageLayout>
            <div className={"h-screen flex justify-center items-center"}>
                <a href={"/"}>
                    <div className={"flex flex-col justify-center items-center shadow white-bg py-3 px-4 md:px-6 md:py-5 lg:px-7 lg:py-6 rounded-sm"}>
                        <p className={"text-center"}>Thank you, your message has been received.</p>
                        <Animation styles={"h-20 w-20 mt-8"} data={logo}/>
                    </div>
                </a>
            </div>
        </PageLayout>
    )
}

export default Success;
